import { CardContent } from "@/Components/ui/card"
import { Separator } from "@/Components/ui/separator"
import { lang } from "@/Hooks/Functions"
import { cn } from "@/lib/utils"
import MenuPopover from "@/Modules/MenuPopover"
import { Icon } from "@iconify/react"
import { Link, usePage } from "@inertiajs/react"
import { memo } from "react"
import { Img } from "react-image"

export const userLinks = [
    { href: "dashboard", label: lang('My Dashboard') },
    { href: "profile.mine", label: lang('My Profile') },
    { href: "designs.mine-index", label: lang('My Designs') },
    { href: "templates.mine-index", label: lang('My Templates') },
    { href: "orders.mine", label: lang('My Orders') },
    { href: "cart.mine", label: lang('My Cart') },
]

const UserPopover = ({ iconColor = "white" }) => {
    const { auth } = usePage().props;

    const isUserAdminOrManager = auth?.user?.is_admin_or_manager;

    return <MenuPopover
        positions={["bottom", "left", "right", "top"]}
        bgColor="white"
        textColor="gray"
        label={
            <div className="flex relative flex-row w-fit justify-center items-center">
                <Img
                    src={auth?.user.image}
                    width={45}
                    height={45}
                    className="aspect-square border-2 border-primary rounded-xl hover:text-teal-600"
                />
                <Icon icon="ei:chevron-down" className="font-semibold text-teal-600 absolute bottom-[1px] left-[1px]" />
            </div>
        }
    >
        <CardContent className="flex flex-col py-2 justify-start items-center gap-2 min-w-[200px] max-w-[300px]">
            {userLinks.filter(i => i.href !== route().current()).map((link) => (<div key={link.href} className="flex flex-col gap-1 items-center justify-center w-full">
                <Link
                    className={cn("flex py-1 px-4 w-full font-semibold text-foreground hover:text-teal-600")}
                    href={route(link.href)}>
                    {link.label}
                </Link>
                <Separator />
            </div>)
            )}
            {isUserAdminOrManager && <>
                <Link
                    className={cn("flex py-1 px-4 w-full font-semibold text-foreground hover:text-teal-600")}
                    href={route('admin-dashboard')}>
                    {lang('Admin Dashboard')}
                </Link>
                <Separator />
            </>}
            <Link
                method="post"
                href={route("logout")}
                as="button"
                className="flex py-1 px-4 w-full font-semibold text-foreground hover:text-teal-600"
            >
                {lang('Logout')}
            </Link>
        </CardContent>
    </MenuPopover>
}

export default memo(UserPopover)
